var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center etn-spinner-wrapper",
    class: _vm.spinnerClass
  }, [_vm._m(0), _c('div', {
    staticClass: "etn-spinner-text"
  }, [_vm._t("default", function () {
    return [_vm._v("Loading...")];
  })], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "etn-spinner mb-2"
  }, [_c('div', {
    staticClass: "left"
  }), _c('div', {
    staticClass: "right"
  })]);

}]

export { render, staticRenderFns }