var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    attrs: {
      "id": "sidebar",
      "aria-label": "Main Navigation",
      "data-cy": "navbar"
    }
  }, [_c('div', {
    staticClass: "Navbar-logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm.$auth && _vm.$auth.settings.showLogo ? _c('div', {
    staticClass: "logo-wrapper secondary-logo"
  }, [_c('div', {
    staticClass: "secondary-logo--image"
  }, [_c('img', {
    attrs: {
      "src": _vm.$auth.settings.logoUrl,
      "alt": "Company Logo"
    }
  })]), _c('div', [_c('div', {
    staticClass: "secondary-logo--text"
  }, [_vm._v(_vm._s(_vm.$t('POWERED_BY')))]), _c('Logo', {
    attrs: {
      "white": true
    }
  })], 1)]) : _c('div', {
    staticClass: "logo-wrapper"
  }, [_c('Logo', {
    attrs: {
      "white": true
    }
  })], 1)]), _c('button', {
    staticClass: "btn btn-outline-primary d-lg-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClickMenu
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-times"
  })])], 1), _c('div', {
    staticClass: "js-sidebar-scroll",
    class: {
      'is-secondary-logo': _vm.$auth && _vm.$auth.settings.showLogo
    }
  }, [_c('div', {
    staticClass: "content-side"
  }, _vm._l(_vm.navItemsBySection, function (items, key) {
    return _c('ul', {
      key: key,
      staticClass: "nav-main mb-1"
    }, [_c('li', {
      staticClass: "nav-main-heading"
    }, [_vm._v(_vm._s(key.toUpperCase()))]), _vm._l(items, function (item) {
      return _c('li', {
        key: item.name,
        staticClass: "nav-main-item",
        on: {
          "click": function ($event) {
            return _vm.onToggleMenu('');
          }
        }
      }, [_c('router-link', {
        staticClass: "nav-main-link",
        class: {
          disabled: item.disabled
        },
        attrs: {
          "active-class": "active",
          "exact-path": "",
          "data-cy": "dashboard",
          "to": {
            name: item.route
          }
        }
      }, [_c('i', {
        staticClass: "nav-main-link-icon fa",
        class: item.icon
      }), _c('span', {
        staticClass: "nav-main-link-name"
      }, [_vm._v(_vm._s(item.name) + " "), item.isBeta ? _c('span', {
        staticClass: "badge badge-info"
      }, [_vm._v("BETA")]) : _vm._e()])])], 1);
    })], 2);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }