import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const initialState = () => {
  return {
    loading: false,
    error: false,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      listCreditNotes: false,
      removeCreditNotes: false,
      uploadCreditNote: false,
      search: false,
      processingInvoices: false,
      deleteInvoice: false,
      getReadings: false,
      submitReading: false,
      deleteReading: false,
      updateReading: false,
      createReading: false,
      getReadingPhoto: false,
      uploadCsv: false,
      uploadContractCsv: false,
      addContract: false,
      updateContract: false,
      deleteContract: false,
      getConsumptions: false,
      uploadConsumption: false,
      importConsumption: false,
      convertCreditNoteToInvoice: false,
      convertInvoiceToCreditNote: false,
      downloadCreditNote: false,
      uploadContractPdf: false,
      getWasteCategories: false,
      invalidateCache: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      search: false,
      processingInvoices: false,
      deleteInvoice: false,
      getReadings: false,
      submitReading: false,
      deleteReading: false,
      updateReading: false,
      createReading: false,
      getReadingPhoto: false,
      uploadCsv: false,
      uploadContractCsv: false,
      addContract: false,
      updateContract: false,
      deleteContract: false,
      getConsumptions: false,
      uploadConsumption: false,
      importConsumption: false,
      convertCreditNoteToInvoice: false,
      convertInvoiceToCreditNote: false,
      uploadContractPdf: false,
      getWasteCategories: false
    },
    schema: [],
    validationErrors: {},
    account: {},
    accounts: [],
    total: 0,
    searchResults: [],
    readings: [],
    totalReadings: 0,
    consumptions: [],
    creditNoteUploadProgress: 0,
    wasteCategories: [],
    tags: []
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    schema: state => state.schema,
    account: state => state.account,
    accounts: state => state.accounts,
    totalAccounts: state => state.total,
    searchResults: state => state.searchResults,
    readings: state => state.readings,
    totalReadings: state => state.totalReadings,
    consumptions: state => state.consumptions,
    creditNoteUploadProgress: state => state.creditNoteUploadProgress,
    validationErrors: state => state.validationErrors,
    wasteCategories: state => state.wasteCategories,
    tags: state => state.tags
  },
  mutations: {
    RESET_STATE: state => {
      Object.assign(state, initialState());
    },
    SET_UPLOAD_CREDIT_LOADING: (state, value) => {
      state.loadingAction.uploadCreditNote = value;
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
