var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-trans-enabled sidebar-dark",
    class: {
      'sidebar-o-xs': _vm.mobileMenu,
      'sidebar-o': _vm.menu
    },
    attrs: {
      "id": "page-container"
    }
  }, [_c('div', {
    attrs: {
      "data-simplebar": "init"
    }
  }), _c('Navbar', {
    on: {
      "onToggleMenu": _vm.onToggleMobileMenu
    }
  }), _c('Header', {
    on: {
      "onToggleMenu": _vm.onToggleMenu
    }
  }), _c('main', {
    attrs: {
      "id": "main-container"
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }