<template>
  <div id="page-header-search" class="overlay-header show">
    <div class="content-header">
      <div class="input-group">
        <div class="input-group-prepend">
          <button type="button" class="btn btn-primary" data-cy="close-btn" @click="$emit('close')">
            <i class="fa fa-fw fa-times-circle"></i>
          </button>
        </div>
        <input
          id="page-header-search-input"
          type="text"
          class="form-control border-0 form-control-alt"
          placeholder="Search..."
          name="page-header-search-input"
          @input="onSearchInput"
        />
      </div>
    </div>
    <div v-if="hasSearched" class="Search-results bg-white text-dark p-4" data-cy="search-results">
      <SpinnerLogo v-if="loadingAction.search" />
      <div v-else-if="searchResults && searchResults.length > 0">
        <h3 class="text-center">Search Results</h3>
        <div v-for="type in searchPriorities" :key="type">
          <div v-if="type === 'entity'">
            <h4 class="mb-3"><i class="fa text-primary fa-sitemap fa-fw mr-1"></i>Entities</h4>
            <table class="table table-borderless table-striped mb-5">
              <tbody>
                <tr v-for="entity in searchResults.filter(r => r.searchType === 'entity')" :key="entity.sourceId">
                  <td>
                    <router-link
                      class="d-flex justify-content-between align-items-center"
                      :to="{ name: 'entity-view', params: { id: entity.sourceId } }"
                      ><span
                        ><div class="font-w600">{{ entity.legalName }}</div>
                        <small v-if="entity.parentEntity">Parent Entity: {{ entity.parentEntity.legalName }}</small></span
                      ><span v-if="$auth.isAdmin" class="font-w600 text-muted">{{ entity.company.name }}</span></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="type === 'asset'">
            <h4 class="mb-3"><i class="fa text-primary fa-buildings fa-fw mr-1"></i>Assets</h4>
            <table class="table table-borderless table-striped mb-5">
              <tbody>
                <tr v-for="asset in searchResults.filter(r => r.searchType === 'asset')" :key="asset.sourceId">
                  <td>
                    <router-link
                      class="d-flex justify-content-between align-items-center"
                      :to="{ name: 'asset-overview', params: { id: asset.sourceId } }"
                    >
                      <div class="d-flex">
                        <div>
                          <img v-if="asset.primaryImageUrl" :src="asset.primaryImageUrl" class="Search-thumb" />
                          <div v-else class="Search-thumb no-image" />
                        </div>
                        <div>
                          <div class="font-w600">{{ asset.siteName }} - {{ asset.addressString }}</div>
                          <small v-if="asset.entity">Entity: {{ asset.entity.legalName }}</small
                          ><span v-if="asset.entity?.parentEntity?.legalName"
                            ><i class="fa fa-pipe fa-fw" /><small>Parent Entity: {{ asset.entity.parentEntity.legalName }}</small></span
                          >
                        </div>
                      </div>
                      <div v-if="$auth.isAdmin" class="font-w600 text-muted">{{ asset.company.name }}</div>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="type === 'account'">
            <h4 class="mb-3"><i class="fa text-primary fa-meter fa-fw mr-1"></i>Accounts</h4>
            <table class="table table-borderless table-striped">
              <tbody>
                <tr v-for="account in searchResults.filter(r => r.searchType === 'account')" :key="account.sourceId">
                  <td>
                    <router-link
                      :key="$route.params.accountId"
                      class="d-flex justify-content-between align-items-center"
                      :to="{ name: 'asset-accounts-overview', params: { id: account.assetId, accountId: account.sourceId } }"
                    >
                      <div>
                        <span
                          ><div class="font-w600">
                            <UtilityTypeIcon :type="account.type" :text="false" icon-class="fa-duotone" />{{ account.name }} -
                            {{ account.meterPointNumber }}
                          </div>
                          <small v-if="account.asset">Asset: {{ account.asset.siteName }}</small
                          ><small v-if="account.asset && account.asset.entity"> | Entity: {{ account.asset.entity.legalName }}</small>
                          <span
                            class="badge text-capitalize ml-2"
                            :class="{ 'badge-success': account.status === 'active', 'badge-danger': account.status !== 'active' }"
                          >
                            {{ account.status }} </span
                          ><span v-if="account.parentAccountId" class="badge badge-info text-capitalize mr-2">Sub-meter</span></span
                        >
                      </div>
                      <div v-if="$auth.isAdmin" class="font-w600 text-muted">{{ account.company.name }}</div>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-warning">No search results found.</div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import UtilityTypeIcon from '@/components/UtilityTypeIcon';
import SpinnerLogo from '@/components/SpinnerLogo';

export default {
  name: 'Search',
  components: {
    UtilityTypeIcon,
    SpinnerLogo
  },
  data() {
    return {
      hasSearched: false
    };
  },
  computed: {
    ...mapGetters({
      searchResults: 'globalSearch/searchResults',
      loadingAction: 'globalSearch/loadingAction'
    }),
    searchPriorities() {
      return [...new Set(this.searchResults.map(r => r.searchType))];
    }
  },
  methods: {
    ...mapActions({
      search: 'globalSearch/search'
    }),
    onSearchInput: debounce(async function (e) {
      this.$mixpanel.track('Global Search Started', {
        'Search Query': e.target.value
      });

      if (!e.target.value || e.target.value.length < 3) return false;

      this.hasSearched = true;

      await this.search({ query: e.target.value });

      this.$mixpanel.track('Global Search Completed', {
        'Search Query': e.target.value,
        'Search Results': this.searchResults.length
      });
    }, 500)
  }
};
</script>
<style lang="scss">
.Search-results {
  box-shadow: 0px 6px 10px 0px rgba(68, 68, 68, 0.38);
  border-top: 1px solid #ececec;
}
.Search-thumb {
  width: 80px;
  height: 44px;
  object-fit: cover;
  margin-right: 10px;
  transform: translate3d(0px, 0px, 0.1px);

  &.no-image {
    background-color: #b9b9b9;
  }
}
</style>
