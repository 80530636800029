export default [
  // User Views
  {
    path: '/assets',
    name: 'asset-list',
    component: () => import('./AssetList'),
    meta: {
      title: 'Asset List',
      permissions: '*'
    }
  },
  {
    path: '/assets/wizard',
    name: 'asset-wizard',
    component: () => import('./AssetWizard'),
    meta: { permissions: ['entity_user'] }
  },
  {
    path: '/assets/:id',
    component: () => import('./AssetView'),
    children: [
      {
        name: 'asset-view',
        path: '',
        redirect: { name: 'asset-overview' },
        meta: { permissions: ['account_user'] }
      },
      {
        name: 'asset-overview',
        path: 'overview',
        component: () => import('./tabs/AssetOverview'),
        meta: { permissions: ['account_user'] }
      },
      {
        name: 'asset-accounts',
        path: 'accounts',
        component: () => import('./tabs/AssetAccount'),
        meta: { permissions: ['account_user'] },
        children: [
          {
            name: 'asset-accounts-view',
            path: ':accountId',
            redirect: { name: 'asset-accounts-overview' }
          },
          {
            name: 'asset-accounts-overview',
            path: ':accountId/overview',
            component: () => import('./account/AssetAccountOverview')
          },
          {
            name: 'asset-accounts-consumptions',
            path: ':accountId/consumption',
            component: () => import('./account/AssetAccountConsumptions')
          },
          {
            name: 'asset-accounts-invoices',
            path: ':accountId/invoices',
            component: () => import('./account/AssetAccountInvoices')
          },
          {
            name: 'asset-accounts-credit-notes',
            path: ':accountId/credit-notes',
            component: () => import('./account/AssetAccountCreditNotes')
          },
          {
            name: 'asset-accounts-readings',
            path: ':accountId/readings',
            component: () => import('./account/AssetAccountReadings')
          },
          {
            name: 'asset-accounts-contracts',
            path: ':accountId/contracts',
            component: () => import('./account/AssetAccountContracts')
          },
          {
            name: 'asset-accounts-data-quality',
            path: ':accountId/data-quality',
            component: () => import('./account/AssetAccountDataQuality')
          },
          {
            name: 'asset-accounts-upload',
            path: ':accountId/upload',
            component: () => import('./account/AssetAccountUpload')
          },
          {
            name: 'asset-accounts-edit',
            path: ':accountId/edit',
            component: () => import('./account/AssetAccountEdit')
          }
        ]
      },
      {
        name: 'asset-discussion',
        path: 'discussion/:messageId?',
        component: () => import('./tabs/AssetMessages'),
        meta: { permissions: ['asset_user'] }
      },
      {
        path: 'reports',
        component: () => import('./tabs/AssetReport'),
        meta: { permissions: ['asset_user'] },
        children: [
          {
            name: 'asset-reports',
            path: '',
            component: () => import('./report/AssetReportList')
          },
          {
            name: 'asset-reports-download',
            path: ':reportType/download',
            component: () => import('./report/AssetReportDownload')
          }
        ]
      },
      {
        path: 'projects',
        component: () => import('./tabs/AssetProject'),
        meta: { permissions: ['asset_user'] },
        children: [
          {
            name: 'asset-projects-list',
            path: '',
            component: () => import('./projects/AssetProjectList')
          },
          {
            name: 'asset-projects-view',
            path: ':projectId',
            component: () => import('./projects/AssetProjectView')
          },
          {
            name: 'asset-projects-create',
            path: 'create',
            component: () => import('./projects/AssetProjectCreate')
          },
          {
            name: 'asset-projects-edit',
            path: ':projectId/edit',
            component: () => import('./projects/AssetProjectEdit')
          }
        ]
      },
      {
        name: 'asset-recommendations',
        path: 'recommendations',
        component: () => import('./tabs/AssetRecommendations'),
        meta: { permissions: ['asset_user'] }
      },
      {
        path: 'documents',
        component: () => import('./tabs/AssetDocuments'),
        meta: { permissions: ['asset_user'] },
        children: [
          { name: 'asset-documents-list', path: '', component: () => import('./documents/AssetDocumentsList') },
          { name: 'asset-documents-view', path: ':certificateId', component: () => import('./documents/AssetCertificateView') }
        ]
      },
      {
        name: 'asset-risks',
        path: 'risks',
        component: () => import('./tabs/AssetRisk'),
        meta: { permissions: ['asset_user'] }
      },
      {
        name: 'asset-tenants',
        path: 'tenants',
        component: () => import('./tabs/AssetTenant'),
        meta: { permissions: ['asset_user'] }
      },
      {
        name: 'asset-manage',
        path: 'manage',
        component: () => import('./tabs/AssetManage'),
        meta: { permissions: ['asset_user'] }
      }
    ]
  }
];
