import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'reading',
    plural: 'readings'
  }),

  SUBMIT_READING_REQUEST: state => {
    state.loadingAction.submitReading = true;
    state.errorAction.submitReading = false;
    state.validationErrors = {};
  },
  SUBMIT_READING_SUCCESS: state => {
    state.loadingAction.submitReading = false;
    state.errorAction.submitReading = false;
    state.validationErrors = {};
  },
  SUBMIT_READING_ERROR: (state, error) => {
    state.loadingAction.submitReading = false;
    state.errorAction.submitReading = error;
    state.validationErrors = error?.formMessages || {};
  },

  EDIT_SUBMISSION_REQUEST: state => {
    state.loadingAction.editSubmission = true;
    state.errorAction.editSubmission = false;
    state.validationErrors = {};
  },
  EDIT_SUBMISSION_SUCCESS: state => {
    state.loadingAction.editSubmission = false;
    state.errorAction.editSubmission = false;
    state.validationErrors = {};
  },
  EDIT_SUBMISSION_ERROR: (state, error) => {
    state.loadingAction.editSubmission = false;
    state.errorAction.editSubmission = error;
    state.validationErrors = error.formMessages;
  },

  UPLOAD_PHOTO_REQUEST: state => {
    state.loading = true;
    state.error = '';

    state.photoUploadProgress = 0;

    state.loadingAction.submitReading = true;
    state.errorAction.submitReading = false;
  },
  UPLOAD_PHOTO_SUCCESS: state => {
    state.loading = false;
    state.error = '';

    state.photoUploadProgress = 100;

    state.loadingAction.submitReading = false;
    state.errorAction.submitReading = false;
  },
  UPLOAD_PHOTO_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;

    state.photoUploadProgress = 0;

    state.loadingAction.submitReading = false;
    state.errorAction.submitReading = error;
  },
  UPLOAD_PHOTO_PROGRESS: (state, progress) => {
    state.photoUploadProgress = progress;
  },

  GET_READING_PHOTO_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getReadingPhoto = true;
    state.errorAction.getReadingPhoto = false;
  },
  GET_READING_PHOTO_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getReadingPhoto = false;
    state.errorAction.getReadingPhoto = false;

    state.photoUrl = body.url;
  },
  GET_READING_PHOTO_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getReadingPhoto = false;
    state.errorAction.getReadingPhoto = error;
  }
};
