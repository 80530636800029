import router from '../router';

// This just controls UI elements that the user can see
// IMPORTANT NOTE: The backend must still enforce permissions, as the user could easily bypass this

export const usePermissions = auth => {
  function route(route) {
    const matchedRoutes = route.matched || [];
    const routePermissions = matchedRoutes.map(r => r.meta?.permissions || []).flat();

    if (route.meta?.permissions === '*') return true;

    if (auth.isAdmin) return true;

    if (routePermissions && routePermissions.length) {
      return routePermissions.some(code => auth.permissions.permissions.some(p => p.code === code));
    }

    return false;
  }

  function routeName(routeName) {
    const routerRoute = router.resolve({ name: routeName }).route;

    return route(routerRoute);
  }

  function isCompanyUser() {
    if (auth.isAdmin) return true;

    return auth.permissions.permissions.some(p => ['company_user', 'company_admin'].includes(p.code));
  }

  function has(id) {
    if (Array.isArray(id)) {
      return id.some(i => has(i));
    }

    return auth.permissions.permissions.some(p => p.code === id);
  }

  function write(type, obj) {
    if (isCompanyUser()) return true;

    // If the user has write access due to having write access to parent
    if (type === 'account') {
      // Check if the user has write access to the asset, entity or account above this account
      const hasAccountAccess = auth.permissions.accounts.write.some(id => id === obj._id);
      if (hasAccountAccess) return true;

      const hasAssetAccess = auth.permissions.assets.write.some(id => id === obj.assetId);
      if (hasAssetAccess) return true;

      const hasEntityAccess = auth.permissions.entities.write.some(id => id === obj.entityId);
      if (hasEntityAccess) return true;
    } else if (type === 'asset') {
      // Check if the user has write access to the entity above this asset
      const hasAssetAccess = auth.permissions.assets.write.some(id => id === obj._id);
      if (hasAssetAccess) return true;

      const hasEntityAccess = auth.permissions.entities.write.some(id => id === obj.entityId);
      if (hasEntityAccess) return true;
    } else if (type === 'entity') {
      // Check if the user has write access to the entity above this entity
      const hasEntityAccess = auth.permissions.entities.write.some(id => id === obj._id);
      if (hasEntityAccess) return true;
    }

    return false;
  }

  return {
    has,
    route,
    routeName,
    write,
    isCompanyUser
  };
};

export default {
  install(Vue) {
    Vue.prototype.$permissions = usePermissions(Vue.prototype.$auth);
  }
};
