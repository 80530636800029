import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'account',
    plural: 'accounts'
  }),
  ...mutationFactory(
    {
      singular: 'account',
      plural: 'accounts'
    },
    {
      singular: 'credit-note',
      plural: 'credit-notes'
    },
    {
      fileActions: true,
      updateSubDocument: true
    }
  ),
  UPLOAD_ACCOUNT_INVOICE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadInvoice = true;
    state.errorAction.uploadInvoice = false;
  },
  UPLOAD_ACCOUNT_INVOICE_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadInvoice = false;
    state.errorAction.uploadInvoice = false;
  },
  UPLOAD_ACCOUNT_INVOICE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadInvoice = false;
    state.errorAction.uploadInvoice = error;
  },

  ACCOUNT_JOB_PROGRESS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.processingInvoices = true;
    state.errorAction.processingInvoices = false;
  },
  ACCOUNT_JOB_PROGRESS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.processingInvoices = false;
    state.errorAction.processingInvoices = false;

    state.account.processingInvoices = body;
  },
  ACCOUNT_JOB_PROGRESS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.processingInvoices = false;
    state.errorAction.processingInvoices = error;
  },

  DELETE_ACCOUNT_INVOICE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteInvoice = true;
    state.errorAction.deleteInvoice = false;
  },
  DELETE_ACCOUNT_INVOICE_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.deleteInvoice = false;
    state.errorAction.deleteInvoice = false;

    state.account.invoices = body.invoices;
  },
  DELETE_ACCOUNT_INVOICE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteInvoice = false;
    state.errorAction.deleteInvoice = error;
  },

  ADD_CONTRACT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.validationErrors = {};
    state.loadingAction.addContract = true;
    state.errorAction.addContract = false;
  },
  ADD_CONTRACT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.validationErrors = {};
    state.loadingAction.addContract = false;
    state.errorAction.addContract = false;

    state.account.contracts = body;
  },
  ADD_CONTRACT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error.body;
    state.loadingAction.addContract = false;
    state.errorAction.addContract = error;
  },
  UPDATE_CONTRACT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateContract = true;
    state.errorAction.updateContract = false;
    state.validationErrors = {};
  },
  UPDATE_CONTRACT_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.updateContract = false;
    state.errorAction.updateContract = false;
    state.validationErrors = {};
  },
  UPDATE_CONTRACT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error.body;
    state.loadingAction.updateContract = false;
    state.errorAction.updateContract = error;
  },

  DELETE_CONTRACT_REQUEST: (state, { contractId }) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteContract = contractId;
    state.errorAction.deleteContract = false;
  },
  DELETE_CONTRACT_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.deleteContract = false;
    state.errorAction.deleteContract = false;

    state.account.contracts = body;
  },
  DELETE_CONTRACT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteContract = false;
    state.errorAction.deleteContract = error;
  },
  TOGGLE_CONTRACT_STATUS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.toggleContractStatus = true;
    state.errorAction.toggleContractStatus = false;
    state.validationErrors = {};
  },
  TOGGLE_CONTRACT_STATUS_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.toggleContractStatus = false;
    state.errorAction.toggleContractStatus = false;
    state.validationErrors = {};
  },
  TOGGLE_CONTRACT_STATUS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error.body;
    state.loadingAction.toggleContractStatus = false;
    state.errorAction.toggleContractStatus = error;
  },
  DOWNLOAD_CONTRACT_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.downloadContract = true;
    state.errorAction.downloadContract = false;
  },
  DOWNLOAD_CONTRACT_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.downloadContract = false;
    state.errorAction.downloadContract = false;
  },
  DOWNLOAD_CONTRACT_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.downloadContract = false;
    state.errorAction.downloadContract = error;
  },

  GET_READINGS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getReadings = true;
    state.errorAction.getReadings = false;
    state.validationErrors = {};
  },
  GET_READINGS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getReadings = false;
    state.errorAction.getReadings = false;
    state.validationErrors = {};

    state.readings = body.data;
    state.totalReadings = body.total;
  },
  GET_READINGS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getReadings = false;
    state.errorAction.getReadings = error;
  },

  SUBMIT_READING_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.submitReading = true;
    state.errorAction.submitReading = false;
  },
  SUBMIT_READING_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.submitReading = false;
    state.errorAction.submitReading = false;
  },
  SUBMIT_READING_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.submitReading = false;
    state.errorAction.submitReading = error;
  },

  GET_READING_PHOTO_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getReadingPhoto = true;
    state.errorAction.getReadingPhoto = false;
  },
  GET_READING_PHOTO_SUCCESS: (state, { id, url }) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getReadingPhoto = false;
    state.errorAction.getReadingPhoto = false;

    state.readings = state.readings.map(reading => ({
      ...reading,
      photoUrl: reading._id === id ? url : undefined
    }));
  },
  GET_READING_PHOTO_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getReadingPhoto = false;
    state.errorAction.getReadingPhoto = error;
  },

  UPDATE_READING_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.updateReading = true;
    state.errorAction.updateReading = false;
    state.validationErrors = {};
  },
  UPDATE_READING_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.updateReading = false;
    state.errorAction.updateReading = false;
    state.validationErrors = {};
  },
  UPDATE_READING_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.updateReading = false;
    state.errorAction.updateReading = error;
  },

  CREATE_READING_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.createReading = true;
    state.errorAction.createReading = false;
    state.validationErrors = {};
  },
  CREATE_READING_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.createReading = false;
    state.errorAction.createReading = false;
    state.validationErrors = {};
  },
  CREATE_READING_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.createReading = false;
    state.errorAction.createReading = error;
  },

  DELETE_READING_REQUEST: (state, { id }) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.deleteReading = id;
    state.errorAction.deleteReading = false;
  },
  DELETE_READING_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.deleteReading = false;
    state.errorAction.deleteReading = false;
  },
  DELETE_READING_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.deleteReading = false;
    state.errorAction.deleteReading = error;
  },
  UPLOAD_CSV_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadCsv = true;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_PROGRESS: (state, progress) => {
    state.loadingAction.uploadCsv = progress;
  },
  UPLOAD_CSV_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = false;
  },
  UPLOAD_CSV_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadCsv = false;
    state.errorAction.uploadCsv = error;
  },
  UPLOAD_CONTRACT_CSV_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadContractCsv = true;
    state.errorAction.uploadContractCsv = false;
  },
  UPLOAD_CONTRACT_CSV_PROGRESS: (state, progress) => {
    state.loadingAction.uploadContractCsv = progress;
  },
  UPLOAD_CONTRACT_CSV_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadContractCsv = false;
    state.errorAction.uploadContractCsv = false;
  },
  UPLOAD_CONTRACT_CSV_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadContractCsv = false;
    state.errorAction.uploadContractCsv = error;
  },

  SEARCH_ACCOUNTS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.search = true;
    state.errorAction.search = false;
  },
  SEARCH_ACCOUNTS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.search = false;
    state.errorAction.search = false;
    state.searchResults = body;
  },
  SEARCH_ACCOUNTS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.search = false;
    state.errorAction.search = error;
  },
  GET_CONSUMPTIONS_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getConsumptions = true;
    state.errorAction.getConsumptions = false;
  },
  GET_CONSUMPTIONS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getConsumptions = false;
    state.errorAction.getConsumptions = false;

    state.consumptions = body;
  },
  GET_CONSUMPTIONS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getConsumptions = false;
    state.errorAction.getConsumptions = error;
  },

  UPLOAD_CONSUMPTION_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadConsumption = true;
    state.errorAction.uploadConsumption = false;
  },
  UPLOAD_CONSUMPTION_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadConsumption = false;
    state.errorAction.uploadConsumption = false;
  },
  UPLOAD_CONSUMPTION_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.uploadConsumption = false;
    state.errorAction.uploadConsumption = error;
  },

  UPLOAD_CONTRACT_PDF_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.uploadContractPdf = true;
    state.errorAction.uploadContractPdf = false;
  },
  UPLOAD_CONTRACT_PDF_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.uploadContractPdf = false;
    state.errorAction.uploadContractPdf = false;
  },
  UPLOAD_CONTRACT_PDF_ERROR: (state, error) => {
    state.loading = false;
    state.error = error.body;
    state.loadingAction.uploadContractPdf = false;
    state.errorAction.uploadContractPdf = error;
  },

  IMPORT_CONSUMPTION_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.importConsumption = true;
    state.errorAction.importConsumption = false;
  },
  IMPORT_CONSUMPTION_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.importConsumption = false;
    state.errorAction.importConsumption = false;
  },
  IMPORT_CONSUMPTION_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.importConsumption = false;
    state.errorAction.importConsumption = error;
  },

  CONVERT_INVOICE_TO_CREDIT_NOTE_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.convertInvoiceToCreditNote = true;
    state.errorAction.convertInvoiceToCreditNote = false;
  },
  CONVERT_INVOICE_TO_CREDIT_NOTE_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.convertInvoiceToCreditNote = false;
    state.errorAction.convertInvoiceToCreditNote = false;
  },
  CONVERT_INVOICE_TO_CREDIT_NOTE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.convertInvoiceToCreditNote = false;
    state.errorAction.convertInvoiceToCreditNote = error;
  },

  CONVERT_CREDIT_NOTE_TO_INVOICE_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.convertCreditNoteToInvoice = id;
    state.errorAction.convertCreditNoteToInvoice = false;
  },
  CONVERT_CREDIT_NOTE_TO_INVOICE_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.convertCreditNoteToInvoice = false;
    state.errorAction.convertCreditNoteToInvoice = false;
  },
  CONVERT_CREDIT_NOTE_TO_INVOICE_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.convertCreditNoteToInvoice = false;
    state.errorAction.convertCreditNoteToInvoice = error;
  },
  DOWNLOAD_CREDIT_NOTE_REQUEST: (state, id) => {
    state.loadingAction.downloadCreditNote = id;
  },
  DOWNLOAD_CREDIT_NOTE_SUCCESS: state => {
    state.loadingAction.downloadCreditNote = false;
  },
  SET_VALIDATION_ERRORS: (state, errors) => {
    state.validationErrors = errors;
  },

  GET_WASTE_CATEGORIES_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getWasteCategories = id;
    state.errorAction.getWasteCategories = false;
  },
  GET_WASTE_CATEGORIES_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getWasteCategories = false;
    state.errorAction.getWasteCategories = false;

    state.wasteCategories = body;
  },
  GET_WASTE_CATEGORIES_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getWasteCategories = false;
    state.errorAction.getWasteCategories = error;
  },

  GET_TAGS_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getTags = id;
    state.errorAction.getTags = false;
  },
  GET_TAGS_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getTags = false;
    state.errorAction.getTags = false;

    state.tags = body;
  },
  GET_TAGS_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getTags = false;
    state.errorAction.getTags = error;
  },

  GET_CONSUMPTION_OLD_REQUEST: (state, id) => {
    state.loading = true;
    state.error = '';
    state.loadingAction.getConsumptionOld = id;
    state.errorAction.getConsumptionOld = false;
  },
  GET_CONSUMPTION_OLD_SUCCESS: state => {
    state.loading = false;
    state.error = '';
    state.loadingAction.getConsumptionOld = false;
    state.errorAction.getConsumptionOld = false;
  },
  GET_CONSUMPTION_OLD_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.getConsumptionOld = false;
    state.errorAction.getConsumptionOld = error;
  },

  INVALIDATE_ACCOUNT_REQUEST: state => {
    state.loadingAction.invalidateAccount = true;
  },
  INVALIDATE_ACCOUNT_SUCCESS: state => {
    state.loadingAction.invalidateAccount = false;
  },
  INVALIDATE_ACCOUNT_ERROR: state => {
    state.loadingAction.invalidateAccount = false;
  }
};
