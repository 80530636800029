var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "etn-new-version-box d-none"
  }, [_c('i', {
    staticClass: "fa fa-triangle-exclamation mr-1"
  }), _vm._v("There's a new version of Etainabl available! "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.refresh.apply(null, arguments);
      }
    }
  }, [_vm._v("Click here to refresh.")])]), _c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }