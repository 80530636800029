var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$auth.isAuthenticated && _vm.$auth.user.email ? _c('div', [_c('Main')], 1) : _c('div', {
    staticClass: "bg-primary show",
    attrs: {
      "id": "page-loader"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }