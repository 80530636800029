import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import accountStore from './account';
import accountWizardStore from './accountWizard';
import adminStore from './admin';
import analyticsStore from './analytics';
import automationStore from './automation';
import benchmarkStore from './benchmark';
import assetStore from './asset';
import assetGroupStore from './assetGroup';
import assetWizardStore from './assetWizard';
import companyStore from './company';
import consumptionStore from './consumption';
import entityStore from './entity';
import entityWizardStore from './entityWizard';
import globalSearchStore from './globalSearch';
import importStore from './import';
import invoiceStore from './invoice';
import invoiceTemplateStore from './invoiceTemplate';
import invoiceUploadStore from './invoiceUpload';
import invoiceValidationStore from './invoiceValidation';
import kioskStore from './kiosk';
import kioskLiveStore from './kioskLive';
import logStore from './log';
import marketplaceStore from './marketplace';
import messageStore from './message';
import permissionStore from './permission';
import readingStore from './reading';
import reportStore from './report';
import reportTemplateStore from './reportTemplate';
import settingsStore from './settings';
import socketStore from './socket';
import socketioStore from './socketio';
import scraperRunStore from './scraperRun';
import scheduledReportStore from './scheduledReport';
import supplierStore from './supplier';
import roleStore from './role';
import targetStore from './target';
import recommendationStore from './recommendation';
import userStore from './user';
import utilStore from './util';

const store = {
  modules: {
    account: accountStore,
    accountWizard: accountWizardStore,
    admin: adminStore,
    analytics: analyticsStore,
    automation: automationStore,
    benchmark: benchmarkStore,
    company: companyStore,
    consumption: consumptionStore,
    asset: assetStore,
    assetGroup: assetGroupStore,
    assetWizard: assetWizardStore,
    entity: entityStore,
    entityWizard: entityWizardStore,
    globalSearch: globalSearchStore,
    import: importStore,
    invoice: invoiceStore,
    invoiceTemplate: invoiceTemplateStore,
    invoiceUpload: invoiceUploadStore,
    invoiceValidation: invoiceValidationStore,
    kiosk: kioskStore,
    kioskLive: kioskLiveStore,
    log: logStore,
    marketplace: marketplaceStore,
    message: messageStore,
    permission: permissionStore,
    reading: readingStore,
    report: reportStore,
    reportTemplate: reportTemplateStore,
    scheduledReport: scheduledReportStore,
    scraperRun: scraperRunStore,
    settings: settingsStore,
    supplier: supplierStore,
    role: roleStore,
    target: targetStore,
    recommendation: recommendationStore,
    socket: socketStore,
    socketio: socketioStore,
    user: userStore,
    util: utilStore
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {}
};

export default new Vuex.Store(store);
