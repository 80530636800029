export default {
  SEARCH_REQUEST: state => {
    state.loading = true;
    state.error = '';
    state.loadingAction.search = true;

    state.searchResults = [];
  },
  SEARCH_SUCCESS: (state, body) => {
    state.loading = false;
    state.error = '';
    state.loadingAction.search = false;

    state.searchResults = body;
  },
  SEARCH_ERROR: (state, error) => {
    state.loading = false;
    state.error = error;
    state.loadingAction.search = false;

    state.searchResults = [];
  }
};
