export default [
  // Admin Views
  {
    path: '/portfolios/:id/edit',
    name: 'portfolio-edit',
    component: () => import('./admin/PortfolioEdit')
  },

  // User Views
  {
    path: '/portfolios',
    name: 'portfolio-list',
    component: () => import('./PortfolioList'),
    meta: { permissions: ['entity_user'] }
  },
  {
    path: '/portfolios/manage',
    name: 'portfolio-management',
    component: () => import('./PortfolioManagement'),
    meta: { permissions: ['entity_user'] }
  },
  {
    path: '/portfolios/create',
    name: 'portfolio-create',
    component: () => import('./PortfolioCreate'),
    meta: { permissions: ['entity_user'] }
  },

  {
    path: '/portfolios/:id',
    component: () => import('./PortfolioView'),
    children: [
      {
        path: '',
        redirect: { name: 'portfolio-assets' },
        meta: { permissions: ['entity_user'] }
      },
      {
        path: 'reports',
        component: () => import('./tabs/PortfolioReport'),
        meta: { permissions: ['entity_user'] },
        children: [
          {
            name: 'portfolio-reports',
            path: '',
            component: () => import('./report/PortfolioReportList')
          },
          {
            name: 'portfolio-reports-download',
            path: ':reportType/download',
            component: () => import('./report/PortfolioReportDownload')
          }
        ]
      },
      {
        name: 'portfolio-assets',
        path: 'assets',
        component: () => import('./tabs/PortfolioAssets'),
        meta: { permissions: ['entity_user'] }
      }
    ]
  }
];
