import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {},
    searchResults: []
  },
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    error: state => state.error,
    searchResults: state => state.searchResults
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
