import Vue from 'vue';

export default {
  search: async ({ commit }, { query }) => {
    try {
      commit('SEARCH_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/global-search/search?query=${encodeURIComponent(query)}`);
      commit('SEARCH_SUCCESS', body.results);
      return body.results;
    } catch (e) {
      commit('SEARCH_ERROR', e);
      return e;
    }
  }
};
